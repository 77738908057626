@import '/src/utils/global.scss';

.app {
  // background-color: $background-color;
  background: transparent;
  //   border: solid red 10px;
  //   min-width: 100vw;
  color: $color;
}

body {
  // background: transparent;
  // background-image: url(../public/images/carres/7_Projet_M2/9_image.jpg);
  // background-size: cover;
  // background-position: center;
  // background-attachment: fixed;
}

html {
  min-width: 100%;
  // background-color: $background-color;
}
@font-face {
  font-family: customFont;
  src: url('../assets/Gobold_High.otf');
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}
