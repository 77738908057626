@import '/src/utils/global.scss';

.selectionFilter {
  margin: 15px;
  padding: 15px;
  // border-left: black solid 1.5px;
  // border-right: black solid 1.5px;
  border-top: black solid 1.5px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  // border: solid purple;
  // min-width: fit-content;
  // width: fit-content;
}

.card {
  cursor: pointer;
  font-weight: bold;
  min-width: 150px;
  text-align: center;
  border: black solid 1px;
  margin: 10px 10px;
  padding: 3px;
  border-radius: 0;
}

.cardStyleSelected {
  cursor: pointer;
  font-weight: bold;
  min-width: 150px;
  text-align: center;
  border: black solid 1px;
  background-color: black;
  color: $background-color;
  margin: 10px 10px;
  padding: 3px;
}
