@import '/src/utils/global.scss';

.projectContainer {
  background-color: $background-color;
  min-height: 100svh;

  .contentContainer {
    padding: 50px;
    display: flex;
    flex-direction: column;

    .titleContainer {
      margin-bottom: 20px;

      span {
        font-size: xx-large;
      }
    }

    @media screen and (max-width: 800px) {
      .detailContainer {
        display: inherit;
        flex-direction: column-reverse;

        .textContainer {
          padding-top: 20px;
          text-align: justify;
          font-size: small;
        }

        .imgContainer {
          border-top: solid 1px;
          padding: 0px 10px;

          .carroussel {
            cursor: pointer;
            max-height: 50svh;
            display: flex;
            justify-content: center;
            align-items: center;

            .swiper-slide {
              background-color: $background-color;
            }

            .swiper-button-prev,
            .swiper-button-next {
              color: black;
            }
            .swiper-pagination-bullet-active {
              background-color: black;
            }
            .swiper-pagination-bullet {
              background-color: black;
            }
          }
        }
      }
    }
    @media screen and (min-width: 800px) {
      .detailContainer {
        display: inherit;
        flex-direction: row;
        .textContainer {
          padding-top: 20px;
          border-top: solid 1px;
          max-width: 40%;
          text-align: justify;
          font-size: small;
        }

        .imgContainer {
          padding: 0px 20px;
          max-width: 60%;

          .carroussel {
            cursor: pointer;
            max-height: 50svh;
            display: flex;
            justify-content: center;
            align-items: center;

            .swiper-slide {
              background-color: $background-color;
            }

            .swiper-button-prev,
            .swiper-button-next {
              color: black;
            }
            .swiper-pagination-bullet-active {
              background-color: black;
            }
            .swiper-pagination-bullet {
              background-color: black;
            }
          }
        }
      }
    }
  }
}
.container {
  display: grid;
  top: 20px;
  margin: 20px 0px;
}
.informations {
  position: sticky;

  top: 100px;

  .detail {
    text-align: justify;
    padding: 0px 15px;
    margin-top: 20px;
  }
}

.carroussel {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }
  .swiper-pagination-bullet-active {
    background-color: white;
  }
  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.76);
  }
}

:root {
  --swiper-theme-color: white;
}

.flipBook {
  padding: 20px 20px;
  display: flex;
  justify-content: center;
}
