@import '/src/utils/global.scss';

.photoContainer {
  opacity: 0;
  transition: opacity 1s ease-in-out;

  cursor: pointer;
  width: $cardSize;
  height: $cardSize;
  background-size: cover;
  background-position: center;
  position: relative;
  font-size: 1em;

  .imageContainer {
    font-size: larger;
    border: solid 1px;
    overflow: hidden;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;

      &:hover {
        // filter: grayscale(100%);

        // opacity: 0.3;
        // background-color: gray;
      }
    }

    div {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(95, 95, 95, 0.8);
      color: white;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 0.3s ease-out;
      padding: 3px;
      font-size: smaller;

      display: -webkit-box;
      // max-width: 200px;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      line-height: 125%;
      overflow: hidden;
      // white-space: pre-wrap; /* let the text wrap preserving spaces */
    }

    &:hover {
      > div {
        transform: scaleY(1);

        span {
          font-size: smaller;
        }
      }
    }
  }
}

/* styles.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 2s forwards;
}
