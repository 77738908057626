@import '/src/utils/global.scss';

.homePage {
  background-image: url(../../../public/images/carres/7_Projet_M2/9_image.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;

  position: relative;

  .titles {
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
      margin-top: 20px;
      letter-spacing: 3px;
      font-size: 3rem;
    }
  }
}

.hidden {
  opacity: 0;
}
.categoryHeader {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  transition: opacity 0.5s ease-in-out;
  height: fit-content;

  .headerList {
    //font-size: 1.1em;
    // font-weight: bold;
    font-family: 'customFont, sans-serif';
    margin: 0px 20px;
    padding: 5px;
    height: 50px;
    background: transparent;
    border: none;
    color: white;
    // letter-spacing: 2px;
    font-size: 1.5rem;
    // font-family: 'customFont' sans-serif;

    // background: linear-gradient(
    //   $background-color,
    //   $background-color 50%,
    //   black 50%,
    //   black
    // );
    // background-size: 100% 200%;
    // transition: opacity 0.3s ease-out;

    // &:hover {
    //   background-position: 100% 100%;
    //   color: white;
    //   a {
    //     color: white;
    //   }
    // }
  }
}

section {
  position: relative;
  width: 100%;
  height: 100%;
}
section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;
  // background: -webkit-linear-gradient(
  //   top,
  //   rgba(0, 0, 0, 0) 0,
  //   rgba(0, 0, 0, 0.8) 80%,
  //   rgba(0, 0, 0, 0.8) 100%
  // );
  // background: linear-gradient(
  //   to bottom,
  //   rgba(0, 0, 0, 0) 0,
  //   rgba(0, 0, 0, 0.8) 80%,
  //   rgba(0, 0, 0, 0.8) 100%
  // );
}

.demo a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}
.demo a:hover {
  opacity: 0.5;
}

.section10 a {
  padding-top: 60px;
}
.section10 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
}
.section10 a span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
