@import '/src/utils/global.scss';

.headerBackground {
  background-color: $background-color;
  opacity: 0;
}

.header {
  position: fixed;
  left: 0;
  // position: sticky;
  top: 0;
  width: 100%;
  //height: 5vh;
  padding: 10px;
  //background: rgb(28,25,89);
  // background: linear-gradient(90deg, rgba(28,25,89,0.9752275910364145) 0%, rgba(143,37,154,1) 38%, rgba(93,43,105,1) 100%);
  //background-color: rgb(252, 252, 252);
  // border: solid red 1px;
  font-size: 1.1em;
  font-weight: bold;
  background-color: $background-color;
  transition: opacity 1s ease-in-out;
  color: $color;
  z-index: 1000;

  .headerList {
    //font-size: 1.1em;
    // font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;

    background: linear-gradient(
      $background-color,
      $background-color 50%,
      black 50%,
      black
    );
    background-size: 100% 200%;
    transition: 0.3s ease-out;

    &:hover {
      background-position: 100% 100%;
      color: white;
      a {
        color: white;
      }
    }
  }
  .shadowHeader {
    box-shadow: 0 5px 20px -5px #000;
  }
  @media screen {
    .title {
      //font-size: 1.1em;
      // font-weight: bold;
      // margin-left: 10px;
      // padding: 2px;
      // width: 400px;
      // border: solid 1px;
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 800px) {
    .title {
      display: none;
    }
  }

  .logo {
    position: relative;
    //margin:20px auto;
    //width:20%;
    padding: 2px;
    //padding-bottom:20%;
    //@at-root:white;
    border: 1px solid black;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      border: 1px solid $background-color;
      opacity: 1;
      height: 105%;
      width: 104%;
      border-radius: 20%;
    }
  }
}
