@import '/src/utils/global.scss';

.gridProject {
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  background-color: $background-color;
  justify-content: center;
  grid-gap: 20px;
}

.container {
  padding: 40px;
}
